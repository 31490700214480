<template>

 
    <div>
      <news />
      <br>
      <articles />
      <br>
      <projects_home/>
      <br>

      <photo_home />
      <br>
      <liveHome></liveHome>
      <pray_home />
      <br>
      <videos_home />
      <br>
      <br>
      <apps_home/>
  
      <br>
      <br>
     
      <services_home2 />
      <br>
      <!-- <imamvr />
      <br> -->
      <!-- <shuhada /> -->
      <!-- <br> -->
      <pubHome></pubHome>
      <br>
      <elist_home />
    </div>
    

   
</template>

<script>

  export default {

    components: {
      // BreakingNews:() => import("../../components/home/BreakingNews.vue"),
      pray_home:() => import("../../components/home/pray_home.vue"),
     // panorama_home:() => import("../../components/home/panorama.vue"),
      articles: () => import("../../components/home/articles.vue"),
      // projects_home: () => import("../../components/home/projects_home.vue"),
      projects_home: () => import("../../components/home/projects.vue"),
      videos_home: () => import("../../components/home/videos_home.vue"),
      apps_home: () => import("../../components/home/apps_home.vue"),
      news: () => import("../../components/home/news.vue"),
      services_home2: () => import("../../components/home/services_home2.vue"),
      elist_home: () => import("../../components/home/elist_home.vue"),
      photo_home: () => import("../../components/home/photo_home.vue"),
      liveHome: () => import("../../components/home/live_home.vue"),
      pubHome: () => import("../../components/home/pub_home.vue"),
      // imamvr: () => import("../../components/home/imam_vr.vue")
      // shuhada: () => import("../../components/home/shuhada.vue")

      
    },

    data: () => ({
      expandOnHover: false
    }),
    mounted() {  
      document.title =this.$t("alkafeel_title");
    },
  };
</script>

